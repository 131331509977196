import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { IThread, IThreadCard, Role, CardReply, SubjectType } from "@findex/threads";
import { VaultService, VAULT_ACTION, PreviewAction } from "@findex/vault";
import { Observable, Subscription, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IVaultItem } from "../../../../interfaces/IVaultItem";
import { IVaultState } from "../../../../interfaces/IVaultState";
import { SigningModalComponent } from "../../../signing-modal/signing-modal.component";
import { UploadComponent } from "../../../upload/upload.component";
import { CardStatus } from "@findex/threads/dist/enums/CardStatus";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    DocumentPreviewModalData,
    DocumentPreviewModalComponent
} from "../../../document-preview/document-preview.component";
import {
    environmentCommon,
    EnvironmentSpecificConfig
} from "projects/portal-modules/src/lib/environment/environment.common";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { VaultCountService } from "../../services/vault-count.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { DocumentCategory } from "projects/default-plugins/vault/enums/DocumentCategory";
import { VaultCardRenameItem, VaultCardDeleteItem } from "../../../../types/EditCardRequests";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { IReport } from "../../../../interfaces/IReport";
import { DownloadAction } from "../../../message-attach-modal/message-attach-modal.component";

export enum VaultCardTypes {
    RFI = "rfi",
    ATTACHMENTS = "attachments"
}

@Component({
    selector: "vault-card",
    templateUrl: "./vault-card.component.html",
    styleUrls: ["./vault-card.component.scss"]
})
export class VaultCardComponent implements OnDestroy, OnInit {
    readonly vaultCardTypes = VaultCardTypes;
    readonly roles = Role;
    readonly vaultActions = VAULT_ACTION;
    readonly gaEvents = GA_EVENTS;
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;

    private stateSub: Subscription;
    private navigateSub: Subscription;
    private threadId: string;
    private cardId: string;

    role: Role;
    card$: Observable<IThreadCard>;
    state: IVaultState;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    vaultCardType: VaultCardTypes = VaultCardTypes.ATTACHMENTS;
    rebuilt: boolean;
    documentsUnsigned: number;
    documentsRequireSignature: boolean;
    cardStatuses = CardStatus;
    selectedItem: IVaultItem;
    allowEdit = this.environment.featureFlags.editCardDescription;
    errorMessage: string;
    toggle = false;
    loader = new Loader();
    customCardTitle?: string;
    isThreadActive: Promise<boolean>

    edit$ = new Subject<boolean>();
    state$: Observable<IVaultState>;
    replies$: Observable<CardReply[]>;
    downloadAction: DownloadAction[] = [];

    private dialogRef: MatDialogRef<SigningModalComponent>;
    report: IReport;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private cardResources: CardResources<IVaultState>,
        private dialog: MatDialog,
        private vaultService: VaultService,
        private activatedRoute: ActivatedRoute,
        private cardService: ThreadCardService,
        private router: Router,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        const { threadId, cardId, thread$, card$, state$, role, replies$ } = this.cardResources;

        this.role = role;
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.thread$ = thread$;
        this.card$ = card$;
        this.threadId = threadId;
        this.cardId = cardId;
        this.replies$ = replies$;
        this.state$ = state$.pipe(filter(vaultState => !!(vaultState && vaultState.groups)));
        this.stateSub = state$.subscribe(vaultState => this.updateState(threadId, cardId, vaultState));
    }

    ngOnDestroy() {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }
        if (this.navigateSub) {
            this.navigateSub.unsubscribe();
        }
        this.edit$.unsubscribe();
    }

    openUploadModal() {
        const data = {
            state$: this.state$,
            thread: this.thread$,
            role: this.role
        };

        this.dialog
            .open<any, any>(UploadComponent, {
                data,
                position: { top: "0px" },
                height: "100vh",
                maxWidth: "100vw",
                panelClass: ["mat-dialog-no-styling", "threads-sidebar"]
            })
            .afterClosed()
            .subscribe(() => this.router.navigate(["timelines", this.threadId]));
    }

    async download(item: IVaultItem) {
        const filename = item.files[0].filename;
        const { vaultId, fileId } = item;
        const downloadWindow = window.open("", "_self");
        downloadWindow.location.href = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
    }

    async downloadItem(item: IVaultItem) {
        this.loader.show();
        if (!item.files.length) return;
        await this.download(item);
        this.loader.hide();
    }

    async uploadFile(card: IThreadCard, file: File) {
        const firstVaultId = card.subjects.find(subject => subject.type === SubjectType.Vault)?.id;
        if (!firstVaultId) return;

        this.loader.show();
        try {
            const fileId = await this.vaultService.uploadFile(firstVaultId, file.name, file, DocumentCategory.Document).toPromise();
            const downloadAction: DownloadAction = {
                vaultId: firstVaultId,
                fileId: fileId as string,
                fileName: file.name,
                fileType: file.type
            };
            this.downloadAction.push(downloadAction);
        } finally {
            this.loader.hide();
        }
    }

    async renameItem(item: IVaultItem, displayName: string) {
        const { vault } = environmentCommon.cardsEndpoints;

        this.loader.show();
        try {
            const data: VaultCardRenameItem = {
                vaultId: item.vaultId,
                fileId: item.fileId,
                editAction: "rename",
                displayName
            };

            await this.cardService.editCard<VaultCardRenameItem>(this.threadId, vault, this.cardId, data).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    async deleteItem(item: IVaultItem) {
        const { vault } = environmentCommon.cardsEndpoints;

        this.loader.show();
        try {
            const data: VaultCardDeleteItem = {
                vaultId: item.vaultId,
                fileId: item.fileId,
                editAction: "delete"
            };

            await this.cardService.editCard<VaultCardDeleteItem>(this.threadId, vault, this.cardId, data).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    signItem(item: IVaultItem) {
        this.selectedItem = item;
        this.dialogRef = this.dialog.open(SigningModalComponent, {
            data: item,
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"]
        });

        this.dialogRef.afterClosed().subscribe(async result => {
            if (!result) return;

            this.loader.show();
            try {
                await this.vaultService.sign(item.vaultId, item.fileId, result).toPromise();
            } finally {
                this.loader.hide();
            }
        });
    }

    editCard() {
        this.edit$.next(true);
    }

    async cancelCard() {
        this.errorMessage = null;
        this.loader.show();
        try {
            await this.cardService.cancelCard(this.threadId, this.cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    async removeMessage() {
        this.errorMessage = null;
        this.loader.show();

        try {
            await this.cardService.removeCard(this.threadId, this.cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    async done() {
        this.setDownloadAction();
        this.edit$.next(false);
    }

    private async setDownloadAction(): Promise<void> {
        if(!this.downloadAction.length) {
            return;
        }

        await Promise.all(
            this.downloadAction.map(async action =>
            {
                await this.vaultService.setAction<PreviewAction>(action.vaultId, action.fileId, VAULT_ACTION.Download, {
                    data: action.fileName,
                    type: action.fileType,
                }).toPromise();
            })
        );
    }

    async save(updatedMessage: string) {
        this.errorMessage = null;
        this.loader.show();

        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    openDocumentPreview(item: IVaultItem) {
        const isSignable = item.actions.includes(this.vaultActions.Sign);
        const isSigned = !item.signed;

        const documentPreviewRef = this.dialog.open<DocumentPreviewModalComponent, DocumentPreviewModalData>(
            DocumentPreviewModalComponent,
            {
                data: {
                    item,
                    isSigned,
                    isSignable
                },
                maxWidth: "none",
                panelClass: ["mat-dialog-no-styling", "document-preview-modal"]
            }
        );

        documentPreviewRef.afterClosed().subscribe(async result => {
            if (!result) return;

            this.loader.show();
            try {
                await this.vaultService.sign(item.vaultId, item.fileId, result).toPromise();
            } finally {
                this.loader.hide();
            }
        });
    }

    private navigateToCard() {
        if (this.vaultCardType === VaultCardTypes.RFI) {
            this.openUploadModal();
        }
    }

    private async updateState(threadId: string, cardId: string, state: IVaultState) {
        const { vaultId, fileId } = this.activatedRoute.snapshot.queryParams;

        if (state) {
            const downloadCount = VaultCountService.countActions(state.groups, [
                VAULT_ACTION.Download,
                VAULT_ACTION.Sign
            ]);
            const rfiCount = VaultCountService.countActions(state.groups, [VAULT_ACTION.Rfi]);

            for (const group of state.groups) {
                for (const item of group.items) {
                    if (cardId === cardId && vaultId === item.vaultId && fileId === item.fileId) {
                        this.signItem(item);
                        this.router.navigateByUrl(`/timelines/${threadId}`);
                    }

                    if (group.displayName === DocumentCategory.Report) {
                        this.report = {
                            fileId: item.fileId,
                            vaultId: item.vaultId
                        };
                    }

                    if (this.selectedItem && this.dialogRef && this.dialogRef.componentInstance) {
                        if (item.fileId === this.selectedItem.fileId && item.vaultId === this.selectedItem.vaultId) {
                            this.dialogRef.componentInstance.vaultItem = item;
                        }
                    }
                }
            }

            if (rfiCount > 0) {
                this.vaultCardType = VaultCardTypes.RFI;
            }

            this.state = state;

            if (this.vaultCardType !== VaultCardTypes.RFI && state.groups[0]) {
                this.documentsRequireSignature = state.groups[0].items.some(
                    vaultItem => vaultItem.signed || vaultItem.actions.includes(VAULT_ACTION.Sign)
                );

                this.documentsUnsigned = state.groups[0].items.filter(
                    vaultItem => !vaultItem.signed && vaultItem.actions.includes(VAULT_ACTION.Sign)
                ).length;
            }

            const inconsistentRfi = rfiCount > 0 && downloadCount;
            const inconsistentShare = !rfiCount && !downloadCount;

            if ((inconsistentRfi || inconsistentShare) && !this.rebuilt) {
                //TODO: remove once race condition sorted
                this.rebuilt = true;
                await this.cardService.rebuildCardState(threadId, cardId).toPromise();
            }
        }

        if (!this.navigateSub) {
            this.navigateSub = this.cardResources.navigateTo$.subscribe(() => this.navigateToCard());
        }
    }
}
