import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IThreadCard, Role } from "@findex/threads";
import { PreviewAction, VaultService, VAULT_ACTION } from "@findex/vault";
import { AnalyticsService, GA_EVENTS, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { IDocument } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card-document/create-card-document.component";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { RenameFileModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/rename-file-modal/rename-file-modal.component";
import { CreateReportModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-report-modal/create-report-modal.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { environmentCommon } from "src/environments/environment";
import { DocumentCategory } from "../../enums/DocumentCategory";
import { WindowListenersService } from "../../../../portal-modules/src/lib/shared/services/window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../portal-modules/src/lib/environment/environment.common";

export interface DownloadAction {
    vaultId: string;
    fileId: string;
    fileName: string;
    fileType: string;
}

@Component({
    selector: "app-message-attach-modal",
    templateUrl: "./message-attach-modal.component.html",
    styleUrls: ["./message-attach-modal.component.scss"],
})
export class MessageAttachModalComponent implements OnInit {
    readonly attachFileSizeLimitMB = environmentCommon.attachFileSizeLimitMB;
    readonly attachFileLimitMB = environmentCommon.attachFileLimitMB;
    readonly attachFileLimitErrorMessage = environmentCommon.attachFileLimitErrorMessage;
    readonly uploadFileSizeLimitErrorMessage = environmentCommon.attachFileSizeLimitErrorMessage;
    readonly gaEvents = GA_EVENTS;
    readonly hotJarEvents = HOT_JAR_EVENTS;

    messageFormControl = new FormControl("", [Validators.required]);

    formError: string = null;
    quillError: boolean;
    documents: IDocument[] = [];
    errorMessage: string;
    attachmentErrorMessages = [];
    role$: Observable<Role>;
    isMobileView = false;
    downloadAction: DownloadAction[] = [];

    loader = new Loader();

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private dialogRef: MatDialogRef<MessageAttachModalComponent>,
        private vaultService: VaultService,
        private cardService: ThreadCardService,
        @Inject(MAT_DIALOG_DATA) private data: ICreateCardEvent,
        private unsavedDialogService: UnsavedModalDialogService,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private analytics: AnalyticsService,
    ) {
        this.dialogRef.disableClose = true;
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint
        );
    }

    onChange() {
        this.validateForm(this.messageFormControl);
    }

    ngOnInit() {
        this.role$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole)
        );
    }

    validateFileSize(file: File) {
        return file.size / (1024 * 1024) > this.attachFileSizeLimitMB;
    }

    validateDocumentsLimit(): boolean {
        return this.documents.length >= this.attachFileLimitMB;
    }

    attachFile(file: File) {
        this.attachmentErrorMessages = [];

        if (this.validateFileSize(file)) {
            this.attachmentErrorMessages.push(this.uploadFileSizeLimitErrorMessage.replace("<filename>", file.name));
            return;
        }

        if(this.validateDocumentsLimit()) {
            this.attachmentErrorMessages.push(this.attachFileLimitErrorMessage);
            return;
        }

        this.documents.push({ description: file.name, file });

        this.validateForm(this.messageFormControl);
    }

    renameFile(document: IDocument) {
        this.dialog
            .open(RenameFileModalComponent, { data: document, panelClass: ["centered-modal"] })
            .afterClosed()
            .subscribe((updatedDescription) => {
                if (!updatedDescription) return;
                document.description = updatedDescription;
            });
    }

    // cleans up state when marked as a report
    private resetReportState(document: IDocument): { description: string; file: File } {
        return {
            description: document.description,
            file: document.file,
        };
    }

    markAsReport(index: number) {
        const file = this.documents[index];

        if (file.category === DocumentCategory.Report) {
            this.documents[index] = this.resetReportState(file);
            return;
        }

        this.dialog
            .open<CreateReportModalComponent>(CreateReportModalComponent, {
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: true,
                autoFocus: true,
                data: file,
                panelClass: ["centered-modal"],
            })
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.documents[index] = data;
                }
            });
    }

    removeFile(document: IDocument) {
        const index = this.documents.indexOf(document);
        this.documents.splice(index, 1);
        this.validateForm(this.messageFormControl);
    }

    async close(forceClose = false): Promise<void> {
        if (forceClose || (!this.messageFormControl.dirty && this.documents.length === 0)) {
            await this.setDownloadAction();
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("message-create", [
                "threads-sidebar",
                "threads-sidebar-inner-close-modal",
            ]);
            if (confirmClose) {
                this.dialogRef.close();
            }
        }
    }

    private async setDownloadAction(): Promise<void> {
        if(!this.downloadAction.length) {
            return;
        }

        await Promise.all(
            this.downloadAction.map(async action =>
            {
                await this.vaultService.setAction<PreviewAction>(action.vaultId, action.fileId, VAULT_ACTION.Download, {
                    data: action.fileName,
                    type: action.fileType,
                }).toPromise();
            })
        );
    }

    private validateForm(message: FormControl): boolean {
        this.formError = null;
        this.messageFormControl = message;
        const textValue = this.messageFormControl.value;

        if (
            this.messageFormControl.dirty &&
            (!textValue || this.trimParagraphTags(textValue) === "") &&
            this.documents?.length === 0
        ) {
            this.formError = "Please supply a message or upload an attachment";
            return false;
        }
        return true;
    }

    private trimParagraphTags(message: string): string {
        if (message) {
            return message.replace(/<p>|<\/p>/gi, "").trim();
        } else return message;
    }

    private clear(): void {
        this.messageFormControl.markAsPristine();
        this.messageFormControl.setValue("", { emitEvent: false });
    }

    async sendMessage() {
        if (this.validateForm(this.messageFormControl)) {
            this.loader.show();
            this.errorMessage = null;
            const message = this.messageFormControl.value || "";

            try {
                if (this.documents.length > 0) {
                    this.analytics.recordEvent("mouse-click", this.hotJarEvents.MessageAttachmentCompleteEvent);
                    await this.sendFiles(this.data.thread.id, message, this.documents);
                } else {
                    const { message: messageEndpoint } = environmentCommon.cardsEndpoints;
                    await this.cardService
                        .createCard<any, IThreadCard>(this.data.thread.id, messageEndpoint, { message })
                        .toPromise();
                }
            } catch (err) {
                this.errorMessage = "A problem occurred sending the message";
                throw err;
            } finally {
                this.attachmentErrorMessages = [];
                this.clear();
                this.documents = new Array();
                this.close(true);
                this.loader.hide();
            }
        }
    }

    private async setReportPreview(vaultId: string, fileId: string, file: File, document: IDocument) {
        const actionData = {
            data: file.name,
            type: file.type,
            metaData: {
                reportingPeriod: document.reportingPeriod,
            },
        };
        await this.vaultService
            .setAction<PreviewAction>(vaultId, fileId, VAULT_ACTION.Preview, actionData)
            .toPromise();
    }

    private async setSignable(vaultId: string, fileId: string) {
        const declaration = `I authorise to bind this/these entities to the engagement and confirm acceptance of the Terms of Business`;
        await this.vaultService.setSignable(vaultId, fileId, declaration).toPromise();
    }

    private async sendFiles(threadId: string, message: string, documents: IDocument[]) {
        const { vault } = environmentCommon.cardsEndpoints;
        const card = await this.cardService
            .createCard<any, IThreadCard>(threadId, vault, { description: message })
            .toPromise();
        const vaultId = card.subjects[0].id;

        for (const document of documents) {
            const { description, file, signature, category } = document;
            const fileId = await this.vaultService.uploadFile(vaultId, description, file, category).toPromise();

            if (typeof fileId !== 'string') continue;

            if (category === DocumentCategory.Report) {
                await this.setReportPreview(vaultId, fileId, file, document);
            }

            if (typeof fileId === "string") {
                if (signature) {
                    await this.setSignable(vaultId, fileId);
                }
                const downloadAction = {
                    vaultId,
                    fileId,
                    fileName: file.name,
                    fileType: file.type
                }
                this.downloadAction.push(downloadAction);
            }
        }
    }
}
