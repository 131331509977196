import { Type } from "@angular/core";
import { IPrice } from "@findex/payments-service-sdk";
import { ThreadTypes } from "@findex/threads";
import { CountryCode } from "libphonenumber-js";
import { INewsArticleMapper } from "projects/default-plugins/dashboard/components/news-article/model/INewsArticleMapper";
import { AuditSubtypes } from "projects/default-plugins/vault/types/AuditSubtypes";
import { ICreateRequestMenu } from "projects/default-plugins/vault/types/CreateRequest";
import { ITileModel } from "../findex-ui/components/fx-tile/fx-tile.component";
import { Tour } from "../shared/services/tour.service";
import { NewThreadType } from "../threads-ui/components/new-thread-modal/new-thread-modal.component";
import { IUserProfileExtension } from "../user-profile/components/user-profile/IUserProfileExtension";

type NewsArticleMapper = new (environment?: EnvironmentSpecificConfig) => INewsArticleMapper;

export enum CloseAction {
    CreateThread = "create-thread",
    SameDayFileSync = "sameday-file-sync",
    CancelThread = "cancel-thread",
    CancelMeetings = "cancel-meetings",
}

export interface ICalendarMeetingDuration {
    durationMinutes: number;
    description: string;
    defaultSelection?: boolean;
}

export const defaultCalendarMeetingDurations = [
    { durationMinutes: 15, description: "15 mins" },
    { durationMinutes: 30, description: "30 mins", defaultSelection: true },
    { durationMinutes: 45, description: "45 mins" },
    { durationMinutes: 60, description: "60 mins" },
];

export const taskChannelPrefix = "tasks/threads";

export type MetadataField = { label: string; type?: "chip"; valueLabels?: { [key: string]: string } };

export type FeatureConfig = {
    signupEnabled: boolean;
    signupCountries: CountryCode[];
    subscriptionEnabled: boolean;
    userProfileExtension?: Type<IUserProfileExtension>;
    mobileVerifiedRedirect: string;
    jobStatusEnabled: boolean;
    closeThreadContextMenu: boolean;
    rfiCategories: string[];
    newThreadButton: boolean;
    calendarMeetingDurations: ICalendarMeetingDuration[];
    tours?: Tour[];
    supportEmail?: string;
    serviceDeskUrl?: string;
    windowWidthMenuBreakpoint: number;
    windowWidthTabletBreakpoint: number;
    focusWizardEnabled: boolean;
    // Temp - enable secondary branding alongside the primary branding. Used for crowe logo
    secondaryBranding?: boolean;

    // use Visory's inline onboarding.
    inlineOnboarding?: boolean;
    accountView?: boolean;
    /**
     * changes the name of the purchasable package, e.g service package or subscription package
     */
    packageDisplayName: string;
    initialThreadDetails?: {
        threadType: ThreadTypes;
    };

    requiresOnboarding?: boolean;
    /**
     * We hope to be able to remove these feature flags at some point.
     */
    temporaryFeatureFlags: {
        staffTitleOverride?: string;
        hideThreadTypeMyTasks?: boolean;
    };
    addParticipants: boolean;
    inviteClients: boolean;
    threadParticipantDetail: boolean;
    showAgreePoliciesLogin: boolean;
    selectStaffAnimationLoadTimeRange?: {
        min: number; // millis
        max: number; // millis
    };

    //This will one day be pulled from a resource pack
    text: {
        loginTitle?: string;
        loginDescription?: string;
        loginAction?: string;
        loginFooter?: string;
        signUpTitle?: string;
        signupInstruction?: string;
        signupAction?: string;
        createPasswordTitle?: string;
        createPasswordDescription?: string;
        createPasswordAction?: string;
        verifyEmailTitle?: string;
        verifyEmailDescription?: string;
        verifyMobileTitle?: string;
        verifyMobileDescription?: string;
        verifyMobileAction?: string;
        verifyMobileFooter?: string;
        verifyCodeTitle?: string;
        verifyCodeDescription?: string;
        verifyCodeAction?: string;
        verifyCodeFooter?: string;
        dashboardNewsTitle?: string;
        selectStaffMemberDescription?: string;
        matchLoaderDescription?: string;

        default: {
            loginTitle: string;
            loginDescription: string;
            loginAction: string;
            loginFooter: string;
            signUpTitle: string;
            signupInstruction: string;
            signupAction: string;
            createPasswordTitle: string;
            createPasswordDescription: string;
            createPasswordAction: string;
            verifyEmailTitle: string;
            verifyEmailDescription: string;
            verifyMobileTitle: string;
            verifyMobileDescription: string;
            verifyMobileAction: string;
            verifyMobileFooter: string;
            verifyCodeTitle: string;
            verifyCodeDescription?: string;
            verifyCodeAction: string;
            verifyCodeFooter: string;
            dashboardNewsTitle: string;
            notInvitedToMeeting: string;
        };
    };

    /**
     * Allow user to sign file only after downloading the file
     * Defaults to false
     */
    signingRequiresFileDownloadEnabled: boolean;
    editCardDescription?: boolean;

    /**
     * Whether the app supports nested threads / breadcrumb navigation
     * Defaults to false
     */
    threadListFilterStatus: {
        closed?: string;
        cancelled?: string;
        active: string;
    };
    showPaymentsSubscriptions?: boolean;
    showPaymentsBilling?: boolean;
    createNewPayments: boolean;
    // TODO: ED-1308
    //  After a user has finished making the introduction booking with their AE, a tour will play.
    //  Since this occurs while the backend creates the thread and calendar card, my-tasks will refresh, breaking the tour.
    //  This doesn't occur in myFindex and since the tour runs before the dashboard card loads,
    // it will display no tasks while tour is running, this prevents it
    blockMyTasksRefreshDuringTour: boolean;
    enableBrandLoader: boolean;
    updateAccountMetadataOnVerifyMobile?: boolean;
    accountMetadataConfiguration?: { [name: string]: MetadataField };
    onboardingConfiguration?: {
        selectServices?: {
            title: string;
            subtitle: string;
            tiles: ITileModel[];
        };
        selectIndustry?: {
            title: string;
            subtitle: string;
            tiles: ITileModel[];
        };
        selectGoals?: {
            title: string;
            subtitle: string;
            tiles: ITileModel[];
            mobileImage?: string;
            desktopImage?: string;
        };
        selectNumberOfEmployees?: { title: string; subtitle: string; tiles: ITileModel[] };
    };
    insightsConfiguration?: {
        showInsights?: boolean;
        emptyStateImage: string;
    };
    vaultConfiguration?: {
        showVaultList: true;
    };
    onboardingStaffTitle?: string;
    creatableThreadsConfiguration: NewThreadType[];
    createRequestConfiguration?: ICreateRequestMenu[];
    hideDashboardThreadsStatusCol?: boolean;
    auditSubtypes: Array<AuditSubtypes>;
    showArticlesSectionFromDashboard?: boolean;
};

export type EnvironmentSpecificConfig = {
    appVersion: string;
    appName: string;
    appTheme: string;
    appId: string;
    appUrl: string;
    featureFlags: FeatureConfig;
    production: boolean;
    stage: string;
    sentry: string;
    analytics: {
        google: {
            trackingId?: string;
            tagManagerId?: string;
            tagManagerAuth?: string;
            tagManagerPreview?: string;
        };
    };
    hotJar?: {
        hjid: string;
        hjsv: string;
    };
    freshmarketer?: {
        cdnUrl: string;
    };
    theme: {
        onboardingCentreAligned: boolean;
        formSubmitActionOutlined: boolean;
    };
    searchOptimization: {
        url: string;
    };
    threadsEndpoints: {
        base: string;
    };
    commonEndpoints: {
        base: string;
    };
    sigmaEndpoints: {
        base: string;
    };
    videoChatEndpoints: {
        base: string;
    };
    paymentsEndpoints: {
        base: string;
    };
    calendarEndpoints: {
        base: string;
    };
    publicEndpoints: {
        base: string;
        profileBase: string;
    };
    payments: {
        publishableApiKey: string;
        taxRateIds: {
            [currency: string]: string;
        };
    };
    vaultEndpoints: {
        base: string;
    };
    vaultThreadsEndpoints: {
        base: string;
    };
    notificationsEndpoints: {
        base: string;
        websockets: string;
    };
    threadsWebsockets: string;
    registration: {
        redirectUrl: string;
    };
    emailVerifyUrl: string;
    auth: {
        base: string;
        userPoolId: string;
        userPoolWebClientId: string;
        protectedResources: string[];
        unprotectedResources?: string[];
        includeCredentialsEndpoints: string[];
        forgotPasswordRedirect: string;
        authenticationFlowType: "USER_SRP_AUTH" | "USER_PASSWORD_AUTH";
    };
    activeDirectory: {
        clientId: string;
    };
    opentokApiKey: string;
    news: {
        sourceUrl: string;
        allArticlePath: string;
        fileName: string;
    };
    openMeasures?: {
        endpoint: string;
    };
    policyUrl?: string;
    termsUrl?: string;
    termsOfBusinessUrl?: string;
    termsOfUseUrl?: string;
    disclaimerUrl?: string;
    emailDisclaimerUrl?: string;
    calendarLocation: string;
    signupRedirectUrl?: string;
    errorRedirectUrl: string;
    emailTimelineUrl: string;
    searchRoles?: string[];
    uploadFilesizeLimitMB?: number;
    attachFileSizeLimitMB?: number;
    uploadFilesizeLimitErrorMessage?: string;
    attachFileSizeLimitErrorMessage?: string;
    attachFileLimitErrorMessage?: string;
    textareaCharacterLimit?: number;
    onboardingVideoUrl?: string;
    newsArticleMapper: NewsArticleMapper;
    whitelistedUrls?: string[];
    virtualBackgroundPresets?: {[key: string]: {src: string}}
    cognitoDomainUrl?: string
};

export const environmentCommon = {
    threadsEndpoints: {
        threads: "/threads",
        events: "/events",
        cards: "/cards",
        participants: "/participants",
        inviteParticipants: "/threads/:threadId/invite-participants",
        role: "/role",
        permissions: "/permissions",
        data: "/data",
        users: "/users",
        myProfile: "/users/me",
        staff: "/staff",
        tasks: "/tasks",
        state: "/state",
        reports: "/reports",
        lastLogin: "/users/:userId/lastLogin",
        deleteUser: "/user/:userId",
        timelines: "/timelines",
        timelineGroups: "/timelines/groups",
        workflow: "/workflow",
        steps: "/steps",
        timeestimates: "/timeestimates",
        contacts: "/contacts",
        accounts: "/accounts",
        migrateCalendarCards: "/migrateCalendarCards",
        notifications: "/notifications",
        cardReadStatus: "/readStatus",
        cardTasks: "/cards/tasks",
        actionLink: "/actionLink/:actionId",
    },
    cardsEndpoints: {
        calendar: "calendar",
        bookedCalendar: "calendar/booked",
        videoChat: "videoChat",
        vault: "vault",
        message: "message",
        payment: "payment",
    },
    sigmaEndpoints: {
        onboarding: "/onboarding",
        signUp: "/signup",
        subscription: "/subscription",
        mySubscription: "/subscription/me",
        dashboard: "/dashboard",
        packages: "/packages",
        validPackage: "/valid",
        participant: "/participant",
        insightsReports: "/insights/user",
    },
    videoChatEndpoints: {
        generateToken: "generateToken",
        terminateSession: "terminateSession",
        createSession: "createSession",
        recordings: "recordings",
        download: "download",
    },
    paymentsEndpoints: {
        prices: "prices",
        payment: "payment",
        subscription: "subscription",
        userDetails: "user",
        account: "account",
        currentUserDetails: "me",
        paymentMethod: "paymentMethod",
        paymentMethodPaymentCard: "paymentMethod/card",
        customer: "customer",
        setDefaultPaymentMethod: "customer/defaultPaymentMethod",
        addPaymentMethod: "addPaymentMethod",
        removePaymentMethod: "removePaymentMethod",
        invoices: "invoices",
        completePayment: "complete",
        activateSubscription: "activate",
        setupIntent: "setup",
    },
    calendarEndpoints: {
        upcomingMeetings: "meetings",
        getInvitation: "getInvitation",
        setAppointment: "setAppointment",
        setAttendees: "setAttendees",
        updateInstance: "updateInstance",
        cancelInstance: "cancelInstance",
        cancelAppointment: "cancelAppointment",
        checkAvailability: "checkAvailability",
        publicCheckStaffAvailability: "checkStaffAvailability",
        attendees: "attendees",
    },
    auth: {
        oauth2: "/oauth2",
        userIdPrefix: "cognito",
        invitation: "/invitationDetail",
        invitationLogin: "/invitationLogin",
        invitationVerifyCode: "/invitationVerifyCode",
        region: "ap-southeast-2",
        endpoints: {
            revoke: '/revoke',
            verifyMobile: "/verifyMobile",
            confirmMobile: "/confirmMobile",
            confirmEmail: "/confirmEmail",
            checkUser: "/checkUser",
            myProfile: "/myProfile",
            forgotPassword: "/forgotPassword",
            forgotPasswordConfirm: "/forgotPasswordConfirm",
        },
    },
    activeDirectory: {
        redirectPath: "/login",
        userIdPrefix: "azuread",
        tenant: "b48a9eab-75aa-4a5b-9fd7-f39223245c1a",
    },
    login: {
        staff: "/staff-login",
    },
    searchRoles: {
        client: "Client",
        staff: "Staff",
    },
    uploadFilesizeLimitMB: 30,
    attachFileSizeLimitMB: 2,
    attachFileLimitMB: 6,
    uploadFilesizeLimitErrorMessage: "Sorry <filename> is too large (Max file size 30mb).",
    attachFileSizeLimitErrorMessage: "Sorry <filename> is too large (Max file size 2mb).",
    attachFileLimitErrorMessage: "Sorry, a maximum of 6 files can be uploaded at once.",
    paymentElementStyle: {
        base: {
            fontSize: "16px",
            lineHeight: "18px",
            fontFamily: "Lato",
        },
    },
    paymentsTailoredSubscriptions: {
        prices: [
            {
                id: "tailoredSubscriptionPrice",
                object: "price",
                active: true,
                billing_scheme: "per_unit",
                created: 1598915581,
                currency: "aud",
                livemode: false,
                lookup_key: null,
                metadata: {},
                nickname: "Custom Tailored Subscription",
                product: "tailoredSubscriptionProduct",
                recurring: {
                    aggregate_usage: null,
                    interval: "month",
                    interval_count: 1,
                    trial_period_days: null,
                    usage_type: "licensed",
                },
                tiers_mode: null,
                transform_quantity: null,
                type: "recurring",
                unit_amount: 0,
                unit_amount_decimal: "0",
            } as IPrice,
            {
                id: "tailoredSubscriptionPrice",
                object: "price",
                active: true,
                billing_scheme: "per_unit",
                created: 1598915581,
                currency: "nzd",
                livemode: false,
                lookup_key: null,
                metadata: {},
                nickname: "Custom Tailored Subscription",
                product: "tailoredSubscriptionProduct",
                recurring: {
                    aggregate_usage: null,
                    interval: "month",
                    interval_count: 1,
                    trial_period_days: null,
                    usage_type: "licensed",
                },
                tiers_mode: null,
                transform_quantity: null,
                type: "recurring",
                unit_amount: 0,
                unit_amount_decimal: "0",
            } as IPrice,
        ],
        tailoredSubscriptionId: "tailoredSubscriptionPrice",
    },
    workflow: {
        states: {
            closed: "closed",
            cancelled: "cancelled",
        },
    },
    textareaCharacterLimit: 5000,
    acceptedCountries: [
        { name: "Australia", countryCode: "AU" },
        { name: "New Zealand", countryCode: "NZ" },
    ],
    quillConfig: {
        toolbarState: {
            withToolbar: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["clean"],
                    ["link"],
                ],
            },
        },
        styling: {
            border: "unset",
            "font-size": "1rem",
            "min-height": "6em",
        },
        bound: ".quill-editor-wrapper-input",
        maxHeight: {
            card: 120, //px
            reply: 120, //px
        },
    },
    dateFormats: {
        short: "h:mm a",
        long: "EEE d MMM, h:mm a",
    },
    focusWizard: {
        path: "perform-action"
    },
};

export type Environment = typeof environmentCommon & EnvironmentSpecificConfig;
